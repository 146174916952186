import React from 'react'
import './Footer.scss'
import logo from '../images/logobb.png'
import { Link } from 'react-router-dom'
import Impressum from '../Components/Impressum/Impressum'

const Footer = () => {

   
    const backtoTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
  return (
    <div className='footer-wrapper'>
        <div className='footer-first'>
            <div className='footer-img'>
            <img src={logo} alt="" />
        </div>
        <div className='footer-left' onClick={() => backtoTop()}>
            <Link to="/impressum">
                 <h1>Impressum</h1> 
                

            </Link>
            <Link to="/daten">
                          <h1>Datenschutz</h1> 


            </Link>
            

        </div>
        <div className='footer-right'>
            <h1>Info</h1>
            
            <h4>Adresse: </h4>
                <p>Unterm Romberge 16 - 59821 Arnsberg</p>
              <h4>Tel:</h4>
             <p>02931/7889844</p>
             
                <h4>Email:</h4>
                <p>info@xrbau.de</p>

        </div>


</div>
<div className='footer-sec'>
    <div className='text'>
        <p>Copyright © 2024 | <b>XR Bau GmbH</b></p>
    </div>
    <div className='icons'>
        <Link to="https://www.facebook.com/" target='_blank'><svg className='fb' fill="#737272" width="40px" height="40px" viewBox="-5.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
<title>facebook</title>
<path d="M1.188 5.594h18.438c0.625 0 1.188 0.563 1.188 1.188v18.438c0 0.625-0.563 1.188-1.188 1.188h-18.438c-0.625 0-1.188-0.563-1.188-1.188v-18.438c0-0.625 0.563-1.188 1.188-1.188zM14.781 17.281h2.875l0.125-2.75h-3v-2.031c0-0.781 0.156-1.219 1.156-1.219h1.75l0.063-2.563s-0.781-0.125-1.906-0.125c-2.75 0-3.969 1.719-3.969 3.563v2.375h-2.031v2.75h2.031v7.625h2.906v-7.625z"></path>
</svg></Link>
    <Link to="https://www.instagram.com/" target='_blank'><svg className='insta' fill="#737272" width="40px" height="40px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M20.445 5h-8.891A6.559 6.559 0 0 0 5 11.554v8.891A6.559 6.559 0 0 0 11.554 27h8.891a6.56 6.56 0 0 0 6.554-6.555v-8.891A6.557 6.557 0 0 0 20.445 5zm4.342 15.445a4.343 4.343 0 0 1-4.342 4.342h-8.891a4.341 4.341 0 0 1-4.341-4.342v-8.891a4.34 4.34 0 0 1 4.341-4.341h8.891a4.342 4.342 0 0 1 4.341 4.341l.001 8.891z"/><path d="M16 10.312c-3.138 0-5.688 2.551-5.688 5.688s2.551 5.688 5.688 5.688 5.688-2.551 5.688-5.688-2.55-5.688-5.688-5.688zm0 9.163a3.475 3.475 0 1 1-.001-6.95 3.475 3.475 0 0 1 .001 6.95zM21.7 8.991a1.363 1.363 0 1 1-1.364 1.364c0-.752.51-1.364 1.364-1.364z"/></svg>
</Link>
    </div>
    
</div>
        
    </div>
  )
}

export default Footer