import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import HomePage from "./Components/Home/HomePage";
import Header from './Header/Header'
import Footer from './Footer/Footer'
import './App.css';
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Impressum from "./Components/Impressum/Impressum";
import Uber from "./Components/Uber/Uber";
import Daten from "./Components/Daten/Daten";

function App() {
  return (
    <Router>
    

          <Header/>
          
          <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/leistungen" element={<About/>}/>
     <Route path="/kontakt" element={<Contact/>}/>
     <Route path="/impressum" element={<Impressum/>} />
     <Route path="/uber" element={<Uber/>}/>
     <Route path="/daten" element={<Daten/>}/>

     
    </Routes>
    <Footer/>
      
  </Router>
  );
}

export default App;
