import './Contact.scss';
import React from 'react';

const Contact = () => {

  return (
    <div className="kontakt-wrapper">
      <div className="kontakt-text">
        <h1>- KONTAKT -</h1>
      </div>

      <div className="kontakt-form">
        <div className="form-text">
          <h5>Adresse:</h5>
          <p>Unterm Romberge 16 - 59821 Arnsberg</p>
          <h5>Telefonnummer:</h5>
          <p>02931/ 7889844</p>
          <h5>Email:</h5>
          <p>info@xrbau.de</p>
        </div>
     
      </div>

      <div className="mapp">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2489.118542860431!2d8.054416376157379!3d51.40087651848019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b95fddd2e001dd%3A0xf3f9dca629358d7b!2sUnterm%20R%C3%B6mberge%2016%2C%2059821%20Arnsberg!5e0!3m2!1sen!2sde!4v1708958814509!5m2!1sen!2sde" 
      width="1200" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
       
      </div>
    </div>
  );
};

export default Contact;
