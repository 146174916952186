import React, { useState, useEffect, useContext } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './HomePage.scss';
import { Link } from 'react-router-dom';
import Modal from '../Modal/Modal';
import img1 from '../../images/imageone.jpeg';
import img2 from '../../images/imagetwo.jpg';
import img6 from '../../images/bager.png'

const HomePage = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData2, setSelectedData2] = useState(null);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = [img1, img2];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, []); // Empty dependency array to run effect only once

    return (
        <div className="home-wrapper">
            
            <div className="carousel">
                <div
                    className="carousel-image"
                    style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
                ></div>
                <div className="carousel-text" >
                    <h2>IHR PARTNER FÜR DEN <br />STRASSEN & INNENAUSBAU </h2>
                </div>
            </div>

            <div className='uber-uns'>
                <div className='uber-text'>
                    {/* {language[lang].uberUns.map((el) => (
                        <h1 key={el.path}> {el.title}</h1>

                    ))} */}
                    <h4>Auf unserer Seite finden Sie <br /> Informationen zur Firma XR Bau <br />  und eine Übersicht  unserer Leistungen.
</h4>
                
                </div>
                <div className='uber-img'>
                    <img src={img6} alt="" />
                    <div>
                     
                       

                   
                       </div>

                </div>

            </div>

            {/* <div className='we'>
                <div className='we-text'><h1>{language[lang].whatWeDo[0].title}</h1>
                    <h3>{language[lang].whatWeDo[0].specialization}</h3></div>

                <div className='we-box'>
                    <div className='boxes'>
                    <svg fill="none" width="100px" height="100px" viewBox="0 0 24 24" id="home-alt-1" data-name="Line Color" xmlns="http://www.w3.org/2000/svg" className="icon line-color">
    <path id="primary" d="M19,10V20.3a.77.77,0,0,1-.83.7H14.3V14.1H9.7V21H5.83A.77.77,0,0,1,5,20.3V10" style={{ fill: "none", stroke: "rgb(0, 0, 0)", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2 }} />
    <polyline id="secondary" points="21 12 12 3 3 12" style={{ fill: "none", stroke: "rgb(44, 169, 188)", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2 }} />
</svg>

                        <h2>{language[lang].architecture[0].title}</h2>
                        <p>{language[lang].architecture[0].description}</p>
                    </div>
                    <div className='boxes2'>
                    <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
	 width="100px" height="100px" viewBox="0 0 455.416 455.416"
	>
<g>
	<g>
		<path d="M235.968,52.552c0.39,9.688,0.778,19.376,1.169,29.064c-7.543,0-11.313,0-18.855,0c0.389-9.688,0.779-19.376,1.167-29.064
			c-15.295,0-30.589,0-45.884,0c-30.747,116.771-61.499,233.539-92.245,350.312c41.352,0,82.704,0,124.055,0
			c0.389-9.689,0.778-19.377,1.168-29.064c14.111,0,28.222,0,42.332,0c0.392,9.688,0.78,19.375,1.168,29.064
			c41.352,0,82.703,0,124.055,0c-30.747-116.771-61.498-233.54-92.245-350.312C266.559,52.552,251.264,52.552,235.968,52.552z
			 M216.531,125.133c7.451,0,14.902-0.004,22.354-0.002c1.082,26.939,2.163,53.88,3.247,80.818c-9.616,0-19.231,0-28.848,0
			C214.37,179.012,215.45,152.072,216.531,125.133z M208.29,330.282c1.081-26.938,2.165-53.877,3.247-80.815
			c10.781,0,21.562-0.002,32.342-0.002c1.083,26.938,2.166,53.879,3.247,80.817C234.182,330.282,221.235,330.282,208.29,330.282z"/>
		<path d="M311.929,52.552c-6.248,0-9.373,0-15.621,0c38.961,116.771,77.913,233.541,116.876,350.312c14.077,0,28.155,0,42.232,0
			C407.589,286.091,359.759,169.323,311.929,52.552z"/>
		<path d="M143.487,52.552C95.657,169.324,47.826,286.091,0,402.864c14.078,0,28.155,0,42.233,0
			c38.958-116.771,77.915-233.54,116.875-350.312C152.86,52.552,149.735,52.552,143.487,52.552z"/>
	</g>
</g>
</svg>
                        <h2>{language[lang].hvacEngineering[0].title}</h2>
                        <p>{language[lang].hvacEngineering[0].description}</p>
                    </div>
                  
                </div>
            </div> */}

        

            {isModalOpen && (
                <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
                    <h2>{selectedData2.companyName}</h2>
                    <img src={selectedData2.logo} alt={selectedData2.name} />
                    <Link to={selectedData2.link} target='_blank'>Visit!</Link>
                </Modal>
            )}

        </div>
    );
};

export default HomePage;
