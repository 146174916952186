import React from 'react'
import './Uber.scss'
import bager from '../../images/bager.png'

const Uber = () => {
  return (
    <div className='uber-wrapper'>

<div className="img">
    <img src={bager} alt="" />
</div>
<div className="text">
    
        <h1>
        XR Bau GmbH
        </h1>
        <h3>
        Wurde im Jahr 2009 unter dem Namen LL Innenausbau von Herrn Isufi gegründet und bietet diverse Leistungen im Bereich des Innenausbaus an. Im Jahr 2022 konnte die Firma LL Innenausbau durch die familiäre und fachliche Unterstützung von Herrn Tschepel in XR Bau GmbH umstrukturiert werden. Der Vorteil: Durch die 13-jährige Berufserfahrung von Herrn Tschepel im Straßenbauhandwerk wird das Leistungsspektrum dementsprechend erweitert.
XR Bau GmbH ist ein eingetragender Handwerksbetrieb und wird wie bereits erwähnt von zwei Geschäftsführern betrieben. Ein moderner Fuhrpark, ein eingespieltes, dynamisches Team gehört ebenso zu XR Bau GmbH wie ein breit aufgestelltes Leistungsspektrum.
Gerne überzeugen wir Sie von unseren Leistungen. Nehmen Sie gerne Kontakt mit uns auf.
        
        </h3>
</div>
        
       

    </div>
  )
}

export default Uber