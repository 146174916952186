import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from "../images/logob.png";
import "./Header.scss";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import HomePage from '../Components/Home/HomePage';
import { Link } from 'react-router-dom';


const Header = () => {
 

   
   
    const backtoTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
  const location = useLocation();
  const [toggleBtn, setToggleBtn] = useState(false);

  const toggleMenu = () => {
     console.log("Fire!", toggleBtn);
   
      setToggleBtn(!toggleBtn);
      document.body.classList.toggle("scroll-y");
      window.scrollTo(0, 0);
    
  }

  return (
    <div className="header">
        
        <img onClick={() => backtoTop()} src={logo} alt='Logo' />
        <ul
          onClick={() => toggleMenu()}
          className={`${toggleBtn ? "header-menu" : ""} menu`}
        >
          <li>
            <Link to="/"> HOME</Link>
          </li>
          <li>
            <Link to="/leistungen"> LEISTUNGEN</Link>
          </li>
          <li>
            <Link to="/uber"> ÜBER UNS</Link>
          </li>
          <li>
            <Link to="/kontakt"> KONTAKT</Link>
          </li>
          

        </ul>
        <IconButton className="btn-menu" onClick={() => toggleMenu()}>
            {toggleBtn ? <CloseIcon /> : <MenuIcon />}
          </IconButton>

    </div>
  )
};

export default Header;

