import React from 'react'
import './Impressum.scss'

const Impressum = () => {
  return (
    <div className='impresum'>
      <h1>Impressum</h1>
      <h3>Angaben gemäß § 5 TMG</h3>
      <p>XR Bau GmbH <br />
Unterm Römberge 16 <br />
59821 Arnsberg <br />
Handelsregister: 14665</p>

<h3>Kontakt</h3>
 <p>Telefon: 02931 / 7889844 <br />
E-Mail: info@xrbau.de</p>

<h3>Umsatzsteuer-ID</h3>
<p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE358266693
</p>

<h3>EU-Streitschlichtung</h3>
<p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <br />
https://ec.europa.eu/consumers/odr/. Unsere E-Mail-Adresse finden Sie oben im Impressum. <br />
Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    </div>
  )
}

export default Impressum